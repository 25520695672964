@use "breakpoint" as media;

* {
  	box-sizing: border-box;

  	&::before,
  	&::after {
	  box-sizing: inherit;
	}
}

body {
	background: #fff;
}

hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

ul,
ol {
	margin: 0 0 1.5em 3em;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: 700;
}

dd {
	margin: 0 1.5em 1.5em;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

img {
	height: auto;
	max-width: 100%;
}

figure {
	margin: 1em 0;
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}

/* Links
--------------------------------------------- */
a {
	color: #4169e1;
}

a:visited {
	color: #800080;
}

a:hover,
a:focus,
a:active {
	color: #191970;
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}

/* Forms
--------------------------------------------- */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: #ccc #ccc #bbb;
	border-radius: 3px;
	background: #e6e6e6;
	color: rgba(0, 0, 0, 0.8);
	line-height: 1;
	padding: 0.6em 1em 0.4em;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
	border-color: #ccc #bbb #aaa;
}

button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
	border-color: #aaa #bbb #bbb;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: #666;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 3px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
	color: #111;
}

select {
	border: 1px solid #ccc;
}

textarea {
	width: 100%;
}

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/

/* Navigation
--------------------------------------------- */
.main-navigation {
	display: block;
	width: 100%;
}

.main-navigation ul {
	display: none;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.main-navigation ul ul {
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	float: left;
	position: absolute;
	top: 100%;
	left: -999em;
	z-index: 99999;
}

.main-navigation ul ul ul {
	left: -999em;
	top: 0;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
	display: block;
	left: auto;
}

.main-navigation ul ul a {
	width: 200px;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
	left: auto;
}

.main-navigation li {
	position: relative;
}

.main-navigation a {
	display: block;
	text-decoration: none;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;
}

@media screen and (min-width: 37.5em) {

	.menu-toggle {
		display: none;
	}

	.main-navigation ul {
		display: flex;
	}
}

.site-main .comment-navigation,
.site-main
.posts-navigation,
.site-main
.post-navigation {
	margin: 0 0 1.5em;
}

.comment-navigation .nav-links,
.posts-navigation .nav-links,
.post-navigation .nav-links {
	display: flex;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
	flex: 1 0 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
	text-align: end;
	flex: 1 0 50%;
}

/* Posts and pages
--------------------------------------------- */
.sticky {
	display: block;
}

.post,
.page {
	margin: 0 0 1.5em;
}

.updated:not(.published) {
	display: none;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

/* Comments
--------------------------------------------- */
.comment-content a {
	word-wrap: break-word;
}

.bypostauthor {
	display: block;
}

/* Widgets
--------------------------------------------- */
.widget {
	margin: 0 0 1.5em;
}

.widget select {
	max-width: 100%;
}

/* Media
--------------------------------------------- */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
	display: inline-block;
}

/* Captions
--------------------------------------------- */
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

.wp-caption-text {
	text-align: center;
}

/* Galleries
--------------------------------------------- */
.gallery {
	margin-bottom: 1.5em;
	display: grid;
	grid-gap: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	width: 100%;
}

.gallery-columns-2 {
	grid-template-columns: repeat(2, 1fr);
}

.gallery-columns-3 {
	grid-template-columns: repeat(3, 1fr);
}

.gallery-columns-4 {
	grid-template-columns: repeat(4, 1fr);
}

.gallery-columns-5 {
	grid-template-columns: repeat(5, 1fr);
}

.gallery-columns-6 {
	grid-template-columns: repeat(6, 1fr);
}

.gallery-columns-7 {
	grid-template-columns: repeat(7, 1fr);
}

.gallery-columns-8 {
	grid-template-columns: repeat(8, 1fr);
}

.gallery-columns-9 {
	grid-template-columns: repeat(9, 1fr);
}

.gallery-caption {
	display: block;
}

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */

/* Hide the Posts Navigation and the Footer when Infinite Scroll is in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
	display: none;
}

/* Re-display the Theme Footer when Infinite Scroll has reached its end. */
.infinity-end.neverending .site-footer {
	display: block;
}

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 0.875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
	outline: 0;
}

/* Alignments
--------------------------------------------- */
.alignleft {

	/*rtl:ignore*/
	float: left;

	/*rtl:ignore*/
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}

.alignright {

	/*rtl:ignore*/
	float: right;

	/*rtl:ignore*/
	margin-left: 1.5em;
	margin-bottom: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.5em;
}

// Styling for the temporary landing page while the site is being developed
.landing-page {
  a {
	color: var(--color-WHITE);

	&[href^="mailto:"] {
	  text-decoration: none;
	}
  }

  .contain {
	max-width: 90rem;
	margin: 0 auto;
	padding: 0 2rem;
  }

  header {
	width: 100%;
	background: white;
	text-align: center;

	.logo {
	  padding: 1rem 0;

	  img {
		width: 18rem;
		height: auto;
	  }
	}
  }

  .content {
	.intro-hero {
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;

	  min-height: 70vh;
	  max-height: 80vh;
	  height: 100%;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;

	  &--text {
		color: var(--color-WHITE);

		h1 {
		  line-height: 1.2;
		  font-size: clamp(3rem, 10vw, var(--font-DISPLAY_LARGE));
		}

		p {
		  font-size: clamp(1.5rem, 5vw, var(--font-DISPLAY_SMALL));
		}
	  }
	}

	&-primary {
	  background: var(--color-BLUE_31);
	  padding: 4rem 0;

	  &--text {
		color: var(--color-WHITE);

		h3 {
		  font-weight: 100;
		  line-height: 1.3;
		  font-size: clamp(1.8rem, 5vw, var(--font-HEADLINE_LARGE));
		}

		p {
		  margin-bottom: 2rem;
		  font-size: var(--font-BODY_LARGE);

		  &.new-website-banner {
			margin-top: 4rem;
			margin-bottom: 1rem;
			font-size: var(--font-DISPLAY_SMALL);
		  }

		  &.old-website-link {
			font-size: var(--font-HEADLINE_MEDIUM);
			margin-bottom: 5rem;
		  }

		  &.phone-number {
			margin-bottom: 0.2rem;
			font-size: var(--font-HEADLINE_MEDIUM);

			a {
			  text-decoration: none;
			}
		  }
		}
	  }

	  .inline {
		font-size: var(--font-HEADLINE_MEDIUM);

		display: grid;
		gap: 0;
		max-width: 55%;
		margin: auto;
		grid-template-columns: 1fr;

		@include media.on('>tablet-landscape') {
		  grid-template-columns: 1fr 1fr;
		}

		li {
		  margin: 0;

		  &:before {
			right: -0.5rem;
		  }
		}
	  }
	}
  }

  footer {
	padding: 4rem 0;
	background: var(--color-BLUE_24);

	.footer-content {
	  color: var(--color-WHITE);

	  text-align: center;

	  ul {
		padding: 0;
		margin: 0;

		li {
		  list-style: none;
		}
	  }
	}
  }
}