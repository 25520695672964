@use 'breakpoint' as media;

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Main Brand
$primary: #000000;
$secondary: #000000;
$gradient: linear-gradient(#656565 -60%, #000000);
$state_highlight: #000000;

// CTAs / Buttons
$default_state: $primary;
$hover_state: #000000;
$pressed_state: #000000;

// Colors
$colors: (
    WHITE: #FFFFFF,
    GREY_96: #F5F5F5,
    GREY_92: #EBEBEB,
    GREY_90: #E6E6E6,
    GREY_86: #DBDBDB,
    GREY_80: #CCCCCC,
    GREY_56: #909090,
    GREY_36: #5C5C5C,
    BLUE_96: #E1EAF5,
    BLUE_92: #CEDBEB,
    BLUE_82: #B0BFD1,
    BLUE_48: #405B7A,
    BLUE_45: #154474,
    BLUE_31: #12284E,
    BLUE_24: #0E1F3D,
    BLUE_16: #242629,
    BLUE_12: #1B1C1F,
    BLUE_06: #0E0F0F,
    RED_81: #CF2028,
    RED_70: #B2151D,
    RED_91: #E85158 ,
);

// Typography
$fontSizes: (
    /* base size: typography---body (16px) */
    'BODY': (
        DEFAULT: 1REM,
        LARGE: 1.16REM,
        SMALL: 0.88REM,
    ),
    'DISPLAY': (
        LARGE: 4.75REM,
        MEDIUM: 3.5REM,
        SMALL: 2.75REM,
    ),
    'HEADLINE': (
        LARGE: 2.25REM,
        MEDIUM: 1.75REM,
        SMALL: 1.38REM,
    ),
    'TITLE': (
        LARGE: 2REM,
        MEDIUM: 1.75REM,
        SMALL: 1.38REM,
    ),
    'LABEL': (
        LARGE: 1.03REM,
        MEDIUM: 0.91REM,
        SMALL: 0.78REM,
    ),
    'LEGAL_TEXT': (
        'DEFAULT': 0.75REM,
    ),
);

h1, .h1 {
    font-size: var(--font-HEADLINE_LARGE);
    font-weight: 700;
}

h2, .h2 {
    font-size: var(--font-TITLE-LARGE);
    font-weight: 700;
}

h3, .h3 {
    font-size: var(--font-TITLE_MEDIUM);
    font-weight: 700;
}

h4, .h4 {
    font-size: var(--font-HEADLINE_SMALL);
}

h5, .h5 {
    font-size: var(--font-TITLE_SMALL);
}

h6, .h6 {
    font-size: var(--font-LABEL_LARGE);
}

p, .p {
    font-size: var(--font-BODY_DEFAULT);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;

    font-family: 'Red Hat Display', sans-serif;
}

ul {
    padding: 0;
    margin: 0;

    &.inline {
        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 1rem;

                &:before {
                    content: "•";
                    font-size: 1.2rem;

                    position: absolute;
                    right: -1rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    li {
        list-style: none;
        position: relative;
    }
}

:root {
    /* Color styles */
    @each $color, $value in $colors {
        --color-#{"" + $color}: #{$value};
    }

    /* Text-size styles */
    @each $type, $size in $fontSizes {
        @each $size, $value in $size {
            --font-#{$type}_#{$size}: #{$value};
        }
    }
}

@each $color, $value in $colors {
    .color--#{"" + $color} {
        color: $value;
    }
    .bg--#{"" + $color} {
        background-color: $value;
    }
}

@each $type, $size in $fontSizes {
    @each $size, $value in $size {
        .font--#{$type}_#{$size} {
            font-size: $value;
        }
    }
}

.font {
    &--bold {
        font-weight: 700;
    }
    &--light {
        font-weight: 300;
    }
    &--italic {
        font-style: italic;
    }
    &--uppercase {
        text-transform: uppercase;
    }
    &--underline {
        text-decoration: underline;
    }
    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
    &--left {
        text-align: left;
    }
}

.contain {
    margin: 0 auto;
    width: 100%;
    padding: 0 1em;
    @include media.on(">tablet-landscape") {
        padding: 0 1.5em;
    }
    @include media.on(">desktop") {
        width: 84.25em; //1300px(ish)
        max-width: 100%;
    }
}