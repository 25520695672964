/*!
Theme Name: Amodil
Theme URI: http://underscores.me/
Author: Jask Creative
Author URI: http://www.jaskcreative.co.uk/
Description: Custom theme: Amodil, developed by Jask Creative
Version: 1.0.0
Tested up to: 5.4
Requires PHP: 8.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: amodil
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned.

Amodil is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
!*/

@use "sass:meta";

@include meta.load-css("normalize");
@include meta.load-css("settings");
@include meta.load-css("typography");
@include meta.load-css("main");
@include meta.load-css("blocks");
@include meta.load-css("woocommerce");